.site-2 {
 display: flex;
 height: 100vh;

 @media (max-width: 1024px) {
  flex-direction: column;
  height: auto;
 }

 &__img {

  @media (max-width: 1024px) {
   width: 100%;
   order: 2;
  }

  img {
   height: 100vh;
   width: auto;
   max-width: none;

   @media (max-width: 1024px) {
    height: auto;
    width: 100%;
   }
  }
 }

 &__content {
  padding: 50px 55px;
  height: 100vh;
  overflow-y: auto;

  @media (min-width: 1600px) {
   overflow-y: hidden;
  }

  @media (max-width: 1024px) {
   order: 1;
   height: auto;
  }

  p {
   font-size: 36px!important;
   line-height: 130%;

   @media (max-width: 1024px) {
    font-size: 22px!important;
   }

   &:not(:last-of-type) {
    margin-bottom: 40px;
   }
  }

  img {
   display: block;
   margin-top: 20px;
  }
 }

 .btn-wrap {
  margin-top: 20px;
  text-align: center;

  a {
   display: inline-block;
   padding: 5px 70px;
   border: 2px solid grey;
   font-size: 30px;
   color: #000;
  }
 }
}
