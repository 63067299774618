.site {
 display: flex;
 height: 100vh;

 @media (max-width: 1024px) {
  flex-direction: column;
 }

 &__navbar {
  width: 40%;
  padding: 50px 20px;
  background-color: #fff;

  @media (max-width: 1024px) {
   width: 100%;
  }

  h1 {
   text-align: center;
   font-size: 60px!important;
   font-weight: normal;
  }

  &__logo {
   display: block;
   margin: 50px auto;

   img {
    height: 150px;
    display: block;
    margin: 0 auto;
   }
  }

  &__menu {
   padding: 0;
   list-style: none;

   li {
    text-align: left;

    &:not(:last-of-type) {
     margin-bottom: 45px;
    }

    a {
     color: #000;
     font-size: 40px;
     position: relative;
     text-align: center;

     &:before {
      content: '';
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      height: 2px;
      width: 0;
      background-color: #000;
      @include transition;
     }

     &:hover {
      &:before {
       width: 100%;
      }
     }
    }
   }
  }
 }

 &__content {
  img {
   @media (min-width: 1025px) {
   }
  }
 }
}
