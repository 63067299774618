.site-3 {
 display: flex;
 align-items: center;
 justify-content: center;
 min-height: 100vh;
 padding: 20px 40px;
 overflow-y: hidden;

 &__row {
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 1590px) {
   flex-direction: column;
  }

  &:not(:last-of-type) {
   margin-bottom: 40px;
  }

  &__item {
   display: flex;
   align-items: center;
   justify-content: center;
   
   @media (max-width: 1024px) {
    width: 100%;
   }

   &:not(:last-of-type) {
    margin-right: 20px;

    @media (max-width: 1024px) {
     margin-right: 0;
     margin-bottom: 30px;
    }
   }

   img {
    display: block;
    margin: 0 auto;
    max-height: 250px;

    @media (max-width: 1024px) {
     height: auto;
    }
   }
  }
 }

 .btn-wrap {
  margin-top: 30px;
  text-align: center;

  a {
   display: inline-block;
   padding: 5px 70px;
   border: 2px solid grey;
   font-size: 30px;
   color: #000;
  }
 }
}

// Termék 2,3, 5

.site-t-3 {

 h1,
 h1 a {
  font-size: 40px ;
 }

 &__wrap__right {
  padding: 60px 40px;
 }
}

.site-t-4 {

 h1,
 h1 a {
  font-size: 40px!important ;

  @media (max-width: 1024px) {
   font-size: 28px ;
  }
 }

 .info {
  height: 500px ;

  @media (max-width: 1024px) {
   height: auto ;
  }
 }

 &__wrap__right {
  width: 50%;
  img {
  }
 }

 &__wrap__left {
  width: 90%;
 }

 .paragraph {
 }
}

.site-t-6 {
 height: 100vh;
 .product-title {
  margin-bottom: 60px;

  h1 {
   font-size: 30px;
   font-weight: bold;

   a {
    font-size: 30px;
   }
  }

  h2 {
   font-weight: normal;
   font-size: 20px;
   line-height: 150%;
  }
 }

 p {
  font-size: 20px;
  line-height: 130%;
 }

 &__wrap {
  &__right {
   height: 100vh ;
   width: 45% ;
  }

  &__left {
   width: 45% ;

   img {
    height: 60vh ;
   }

   &__left {
    margin-right: 40px;
    width: 50%;
   }
  }
 }
}

.site-t-6__wrap__left {
 display: flex;
 align-items: flex-start;
 justify-content: space-between;

 &__left {
  img {
   height: 60vh;
   margin-bottom: 40px;
  }
 }
}

.biginfo {
 height: 80vh ;
}

.site-t-5-asd {

 .site-t-4__wrap__right img {
  height: auto ;
 }

 .paragraph {
  &:not(:last-of-type) {
   margin-bottom: 20px;
  }
 }

 .btn-wrap {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;

  a {
   display: inline-block;
   padding: 5px 70px;
   border: 2px solid grey;
   font-size: 30px;
   color: #000;
  }
 }
}

.site-t-5 {

 &__wrap {

  &__row {

   &__left {
    display: flex;

    img {
     height: 70vh;
     float: left;
     @include clearfix;
     margin: 0 40px 40px;
    }
   }
  }
 }
}

.site-t-3-al {
 .btn-wrap {
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;

  a {
   display: inline-block;
   padding: 5px 70px;
   border: 2px solid grey;
   font-size: 30px;
   color: #000;
  }
 }
}

.site-t-2,
.site-t-3,
.site-t-5,
.site-t-6 {

 &__wrap {

  &__row {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;

   @media (max-width: 1024px) {
    flex-direction: column;
   }
  }

  &__left {
   width: 45%;

   img {
    height: 100vh;
    object-fit: cover;

    @media (max-width: 1024px) {
     height: auto;
    }
   }
  }

  &__right {
   height: 100vh;
   overflow-y: hidden;

   @media (max-width: 1024px) {
    height: auto;
    overflow-y: auto;
   }

   .btn-wrap {
    text-align: right;
    margin-top: 20px;
    margin-bottom: 50px;

    @media (max-width: 1500px) {
     margin-left: 0;
     margin-top: 30px;
     text-align: center;
    }

    a {
     display: inline-block;
     padding: 5px 70px;
     border: 2px solid grey;
     font-size: 30px;
     color: #000;

     @media (max-width: 1024px) {
      max-width: 100%;
      font-size: 20px;
     }
    }
   }

   .wrap-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: 1024px) {
     flex-direction: column;
    }
   }

   .product-title {
    margin-bottom: 60px;

    h1 {
     font-size: 40px;
     font-weight: bold;

     @media (max-width: 1024px) {
      font-size: 35px ;
     }

     a {
      font-size: 40px;

      @media (max-width: 1024px) {
       font-size: 25px ;
      }
     }
    }

    h2 {
     font-weight: normal;
     font-size: 30px;
     line-height: 150%;

     @media (max-width: 1024px) {
      font-size: 26px!important
     }
    }
   }

   &__left {
    padding: 40px;

    p {
     font-size: 30px;
     line-height: 130%;

     &:not(:last-of-type) {
      margin-bottom: 40px;
     }
    }

    img {
     margin-top: 20px;
    }
   }

   &__right {
    align-self: flex-end;
    margin-right: 60px;
   }
  }
 }
}

// Termék 1,4
.site-t-1,
.site-t-4 {
 height: 100vh;
 overflow: hidden;

 @media (max-width: 1024px) {
  height: auto;
  overflow: auto;
 }

 .info {
  height: 65vh;
  object-fit: cover;

  @media (max-width: 1500px) {
   height: auto;
   display: block;
   margin: 0 auto;
  }
 }

 &__wrap {

  &__left {

   .product-title {
    margin-bottom: 60px;

    @media (max-width: 1024px) {
     margin-top: 20px;
    }

    h1 {
     font-size: 30px;
     font-weight: bold;

     @media (max-width: 1024px) {
      text-align: center;
     }
    }

    h2 {
     font-weight: normal;
     font-size: 25px;
     line-height: 150%;

     @media (max-width: 1024px) {
      text-align: center;
     }
    }
   }

   img {
    display: block;
    float: left;
    @include clearfix;
    margin: 0 40px 40px;

    @media (max-width: 1024px) {
     float: none;
     display: block;
     order: 2;
     margin: 0 auto;
    }
   }

   p {
    font-size: 24px;
    line-height: 130%;
    margin-left: 40px;
    margin-right: 20px;

    @media (max-width: 1024px) {
     font-size: 18px;
    }
   }
  }

  &__right {
   width: 45% ;

   @media (max-width: 1024px) {
    width: 100% ;
   }

   .info {
    float: right;

    @media (max-width: 1024px) {
     float: none;
    }
   }
  }

  &__row {
   display: flex;
   align-items: flex-start;
   justify-content: space-between;
   margin-bottom: 0;

   @media (max-width: 1500px) {
    flex-direction: column;
   }
  }
 }

 &__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 20px;

  @media (max-width: 1500px) {
   flex-direction: column;
  }

  img {
   height: 120px;

   @media (max-width: 1024px) {
    height: auto;
    margin-top: 40px;
   }
  }

  .btn-wrap {
   margin-left: 30px;
   text-align: center;

   @media (max-width: 1500px) {
    margin-left: 0;
    margin-top: 30px;
   }

   a {
    display: inline-block;
    padding: 5px 70px;
    border: 2px solid grey;
    font-size: 30px;
    color: #000;
   }
  }
 }
}

.site-t-2 {

 .product-title {
  margin-bottom: 20px;
 }

 h1 {
  font-size: 30px;
 }

 h2,p {
  font-size: 28px ;
 }

 &__wrap {

  &__row {
   @media (max-width: 1024px) {
    flex-direction: column;
   }
  }

  &__left {
   width: 40%;

   @media (max-width: 1024px) {
    width: 100%;
   }
  }

  &__right {
   width: 65%;

   @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
   }

   img {
    height: 400px;

    @media (max-width: 1024px){
     height: auto;
    }
   }

   &__right {
    position: relative;

    @media (max-width: 1024px) {
     width: 100%;
    }

    img {
     margin-bottom: 80px;
     margin-right: 60px;
     height: 635px;

     @media (max-width: 1024px) {
      height: 300px;
      display: block;
      margin: 40px auto;
     }
    }
   }
  }

  .btn-wrap {
   position: absolute;
   bottom: 10px;
   right: 10px;
   margin: 0;
   margin-right: 30px;

   @media (max-width: 1024px) {
    position: static;
    bottom: unset;
    right: unset;
    text-align: center;
    margin: 30px auto;
   }
  }
 }
}

.site-t-3 {

 .product-title {
  margin-bottom: 15px ;
 }

 h1 {
  font-size: 40px ;
  margin-left: 20px;

  a {
   font-size: 40px ;
  }
 }

 h2 {
  font-size: 36px ;
  line-height: 110% ;
  margin-top: 20px;
 }

 &__wrap {

  &__left {
   width: 40%;

   @media (max-width: 1024px) {
    width: 100%;
   }
  }

  &__right {
   width: 65%;

   @media (max-width: 1024px) {
    width: 100%;
   }
  }

  &__right {
   overflow: hidden ;

   img {
    height: 480px;
    display: block;

    @media (max-width: 1024px) {
     height: auto;
    }
   }
  }
 }
}

.site-t-1 {
 padding-top: 60px;

 .product-title {
  margin-bottom: 30px;
 }
}

h1 {
 font-size: 30px ;

 a {
  font-size: 30px ;
 }
}

h2,p {
 font-size: 27px!important;
}

.site-t-4 {
 padding-top: 20px;

 .info {
  height: 300px ;
 }

 &__wrap {
 }

 &__wrap__right {
  img {
   height: 80vh;
   @media (max-width: 1024px) {
    height: auto;
   }
  }
 }
}

.site-t-5-asd {
 @media (max-width: 1024px) {
  padding: 0 ;
 }

 .btn-wrap {
  margin-right: 60px ;

  @media (max-width: 1024px) {
   margin: 0 ;
   max-width: 100% ;
   text-align: center ;

   a {
    text-align: center;
    font-size: 18px;
   }
  }
 }

 .product-title {
  margin-bottom: 20px;
 }

 p,h2 {
  font-size: 26px ;
 }

 .btn-wrap {
  text-align: right ;
 }


 .site-t-4__wrap {

  &__left {
   width: 100% ;
   .imsdgsdgf {
    height: 440px;
   }
  }

  &__right {
   img {
    height: 80vh ;
    display: block;
    margin-bottom: 40px;

    @media (max-width: 1024px) {
     height: auto ;
    }
   }
  }
 }
}

.site-t-61 {
 padding: 30px 15px;

 h1 {
  font-size: 30px;
 }

 h2 {
  font-weight: normal;
 }

 .product-title {
  margin-bottom: 40px;
 }

 &__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1024px) {
   flex-direction: column;
  }

  &__left {
   width: 70%;
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;

   @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
   }

   &__1 {
    width: 50%;
    margin-right: 30px;

    @media (max-width: 1024px){
     width: 100%;
    }

    img {
     margin-bottom: 30px;
     display: block;
    }
   }

   &__2 {
    width: 50%;

    @media (max-width: 1024px) {
     width: 100%;
    }
   }
  }

  &__right {
   width: 30%;
   position: relative;

   @media (max-width: 1024px) {
    width: 100%;
   }

   .btn-wrap {
    position: absolute;
    bottom: -55px;
    right: 10px;
    margin: 0;
    margin-right: 30px;
    text-align: center;

    @media (max-width: 1024px){
     position: static;
     bottom: unset;
     right: unset;
    }

    a {
     display: inline-block;
     padding: 5px 70px;
     border: 2px solid grey;
     font-size: 30px;
     color: #000;
    }
   }
  }
 }
}

.site-t-2 h2, .site-t-2 p {
 font-size: 28px ;
}

.site-t-4 {

 .paragraph {
  &:not(:last-of-type) {
   margin-bottom: 10px;
  }
 }
}

.site-t-5-asd {
 .imsdgsdgf {
  height: 570px ;
 }

 p {

 }
}

@media (max-width: 1024px) {
 .site-t-5-asd {
  overflow: auto!important;
  height: 100vh!important;
 }
}
