.site-4 {

 &__wrap {
  padding: 40px 200px 0;

  @media (max-width: 768px) {
   padding: 40px 20px;
  }
 }

 h1 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
 }

 &__row {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
   flex-direction: column;
  }

  &:not(:last-of-type) {
   margin-bottom: 60px;
  }

  img {
   height: 200px;
   display: block;

   @media (max-width: 768px) {
    height: 200px;
   }

   @media (max-width: 1200px) {
    display: block;

    &:not(:last-of-type) {
     margin-bottom: 40px;
    }
   }


   &:not(:nth-child(2n)) {
    margin-right: 60px;

    @media (max-width: 1024px) {
     margin-right: 0;
    }
   }
  }
 }

 p {
  text-align: center;
  font-size: 25px;
  margin-top: 20px;
 }

 .btn-wrap {
  margin-top: 20px;
  text-align: center;

  a {
   display: inline-block;
   padding: 5px 70px;
   border: 2px solid grey;
   font-size: 30px;
   color: #000;
  }
 }
}
