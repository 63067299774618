// COLORS

// Z-INDEXES
$z-1: 1;
$z-2: 2;
$z-3: 3;
$z-4: 4;
$z-5: 5;
$z-6: 6;
$z-7: 7;
$z-8: 8;
$z-9: 9;

// FONTS
$mf: 'Times New Roman';
