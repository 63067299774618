.site-5 {
 height: 100vh;
 overflow-y: hidden;

 @media (max-width: 1110px) {
  overflow-y: auto;
  height: auto;
 }

 &__wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1110px) {
   flex-direction: column;
  }

  &__left {
   width: 70%;
   background-image: url('../../img/contact-bg-min.jpg');
   height: 100vh;
   background-size: cover;
   background-position: top left;
   background-repeat: no-repeat;
   position: relative;

   @media (max-width: 1110px) {
    width: 100%;
   }

   .contact-info {
    position: absolute;
    top: 50px;
    left: 50px;
    font-size: 30px;

    @media (max-width: 1110px) {
     font-size: 20px;
     top: 20px;
     left: 20px;
    }

    a {
     color: blue;
    }
   }
  }

  &__right {
   width: 30%;
   padding: 40px;
   height: 100vh;
   overflow: hidden;

   @media (max-width: 1110px) {
    padding: 10px;
    height: auto;
    width: 100%;
   }

   .dolhay {
    display: block;
    margin: 0 auto;
    height: 300px;
   }

   p {
    font-size: 25px;
    margin: 30px 0;
    text-align: center;
   }

   .hazai {
    display: block;
    margin: 0 auto;
    height: 100px;
   }

   .btn-wrap {
    margin-top: 60px;
    text-align: center;

    a {
     display: inline-block;
     padding: 5px 70px;
     border: 2px solid grey;
     font-size: 30px;
     color: #000;
    }
   }
  }
 }
}


@media (max-width: 1024px) {
 p,h2 {
  font-size: 22px!important;
 }

 h1 {
  font-size: 26px!important;
 }

 .btn-wrap {
  text-align: center!important;

  a {
   font-size: 18px!important;
   padding: 10px 30px!important;
  }
 }
}
