@mixin clearfix {
    &:before, &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    zoom: 1;
}
@mixin inner_a_tag {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
}
@mixin transition {
    transition: all ease-in-out 0.33s;
}