* {
 box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
ul,
li {
 margin: 0;
 padding: 0;
 line-height: 1;
}

body {
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 font-family: $mf;

 &.no-overflow {
  overflow: hidden;

  @media (max-width: 1599px) {
   overflow: auto;
  }
 }
}

a {
 @include transition;
 text-decoration: none;
 color: white;

 &:hover {
  color: blue;
 }
}

button {
 background: none;
 padding: 0;
 border: 0;
 outline: none;
 line-height: 1;
}

svg {
 width: 100%;
 height: 100%;
}

img {
 max-width: 100%;
}

.highlight-link {
 all: unset;
 color: #ff00ff;
 font-size: 25px;
 cursor: pointer;

 &:hover {
  color: #ff00ff;
 }
}

.brands {
 display: flex;
 align-items: center;
 justify-content: flex-start;

 @media (max-width: 1024px) {
  flex-direction: column;
 }

 a {
  display: block;
  height: 80px;

  img {
   height: 100%;
  }

  &:not(:last-of-type) {
   margin-right: 40px;

   @media (max-width: 1024px) {
    margin-right: 0;
   }
  }
 }
}

.img-row {
 display: flex;

 @media (max-width: 1024px) {
  display: block;
 }

 .brand-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
 }

 a {
  height: 70px;
  display: block;

  img {
   height: 70px;;
   display: block;
  }
 }
}

.t3-row {
 display: flex;

 @media (max-width: 1024px) {
  display: block;
 }

 .t3-brands {
  margin-right: 25px;
  a {
   display: block;
   height: 80px;

   img {
    height: 80px;
   }
  }
 }
}

.t-4-brands {
 position: absolute;
 top: 53%;
 left: 0;

 @media (max-width: 1024px) {
  position: static;
  top: unset;
  left: unset;
 }

 a {
  height: 60px;
  display: block;

  img {
   height: 60px!important;
  }
 }
}

.t-4-b-row {
 display: flex;
 align-items: center;
 justify-content: space-between;
 position: absolute;
 width: 80%;
 bottom: 12%;
 left: 14px;

 @media (max-width: 1024px) {
  position: static;
  width: 100%;
  bottom: unset;
  left: unset;
 }

 a {
  height: 80px;
  display: block;

  img {
   height: 85px!important;
  }
 }
}

.t61-row {
 display: flex;
 margin: 0 auto;
 justify-content: center;

 a {
  height: 80px;
  display: block;

  &:nth-child(2) {
   align-self: center;
  }

  &:nth-child(3) {
   align-self: flex-end;
  }

  img {
   height: 80px!important;
  }
 }
}
